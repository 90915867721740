import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/[locale]/(public)/views/IframeActions.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/[locale]/(public)/views/RelatedVideo.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Accordion","AccordionItem","AccordionTrigger","AccordionContent"] */ "/vercel/path0/lib/ui/components/accordion.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Avatar","AvatarImage","AvatarFallback"] */ "/vercel/path0/lib/ui/components/avatar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-tweet/dist/hooks.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-tweet/dist/twitter-theme/icons/icons.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-tweet/dist/twitter-theme/quoted-tweet/quoted-tweet-container.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-tweet/dist/twitter-theme/theme.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-tweet/dist/twitter-theme/tweet-actions-copy.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-tweet/dist/twitter-theme/tweet-container.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-tweet/dist/twitter-theme/tweet-header.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-tweet/dist/twitter-theme/tweet-body.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-tweet/dist/twitter-theme/tweet-media-video.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-tweet/dist/twitter-theme/verified-badge.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-tweet/dist/twitter-theme/tweet-in-reply-to.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-tweet/dist/twitter-theme/tweet-link.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-tweet/dist/twitter-theme/tweet-media.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-tweet/dist/twitter-theme/tweet-info-created-at.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-tweet/dist/twitter-theme/tweet-info.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-tweet/dist/twitter-theme/tweet-actions.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-tweet/dist/twitter-theme/tweet-replies.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-tweet/dist/twitter-theme/quoted-tweet/quoted-tweet-header.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-tweet/dist/twitter-theme/quoted-tweet/quoted-tweet-body.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-tweet/dist/twitter-theme/tweet-not-found.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-tweet/dist/twitter-theme/skeleton.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-tweet/dist/twitter-theme/tweet-skeleton.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["toast","error"] */ "/vercel/path0/node_modules/sonner/dist/index.mjs");
